import React from 'react';
import { FeaturesContainer } from '../styles/styles';

import One from '../static/icons/1a.svg';
import Two from '../static/icons/2a.svg';
import Three from '../static/icons/3a.svg';
import Four from '../static/icons/4a.svg';
import Five from '../static/icons/5a.svg';
import Six from '../static/icons/6a.svg';

const Features = props => {
  return (
    <FeaturesContainer style={{ background: props.background }}>
      <div>
        <h1 className="features-title">
          🎈 Find all your <span>Design Resources</span> in one place
        </h1>
        <p className="features-subtitle">
          Push your design teams / freelancers to
          <br /> design lightning fast!
        </p>
        <div className="features-card-container">
          <div className="features-card-item">
            <div>
              <img alt="img" src={One} />
            </div>
            <h2 className="card-title">All Design Stuff in One Tool</h2>
            <p className="card-content">Images, Icons, Fonts, Colors palettes/gradients, all assets you can think of in a single place.</p>
          </div>
          <div className="features-card-item">
            <div>
              <img alt="img" src={Two} />
            </div>
            <h2 className="card-title">Access anywhere anytime</h2>
            <p className="card-content">Design Lobby is Cloud-based design asset manager, you can access your assets anywhere you want!</p>
          </div>
          <div className="features-card-item">
            <div>
              <img alt="img" src={Three} />
            </div>
            <h2 className="card-title">Visually browsable</h2>
            <p className="card-content">Stop wasting time to search through complicated folders. In Design Lobby all your assets are visible and easy to locate within collections.</p>
          </div>
          <div className="features-card-item">
            <div>
              <img alt="img" src={Four} />
            </div>
            <h2 className="card-title">Tagable and searchable</h2>
            <p className="card-content">Optimized for efficiency with powerful tagging mechanism and searching algorithms.</p>
          </div>
          <div className="features-card-item">
            <div>
              <img alt="img" src={Five} />
            </div>
            <h2 className="card-title">Clean User Interfaces</h2>
            <p className="card-content">Design Lobby is crafted with minimal and sleek design, inspired by designer all around the globe</p>
          </div>
          <div className="features-card-item">
            <div>
              <img alt="img" src={Six} />
            </div>
            <h2 className="card-title">Dark Mood</h2>
            <p className="card-content">Light or dark, your choice.</p>
            <div className="tag">Coming Soon</div>
          </div>
        </div>
      </div>
    </FeaturesContainer>
  );
};

export default Features;
